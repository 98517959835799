function PlansBilling() {
  return (
    <div className="h-full">
      <h1 className="text-2xl font-semibold mb-4">Plans & Billing</h1>
      <p>This is the Plans & Billing page.</p>
    </div>
  );
}

export default PlansBilling;
