import { useUserData } from "../hooks/useUserData";

import Spinner from "../components/spinner";

function Dashboard() {
  const { data: userData, isLoading, isError, error } = useUserData();

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="font-semibold text-red-600">
          Error: {error instanceof Error ? error.message : "An error occurred"}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <h1 className="text-2xl font-semibold mb-4">Dashboard</h1>
      {userData ? (
        <div>
          <p className="mb-4">Your user ID is: {userData.id}</p>
          {/* Add more user data display here as needed */}
        </div>
      ) : (
        <div className="text-center mb-4">No user data available.</div>
      )}
    </div>
  );
}

export default Dashboard;
