import { useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";
import Layout from "./components/layout";
import Spinner from "./components/spinner";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isLoaded, isSignedIn } = useUser();

  if (!isLoaded) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner height="16" />
      </div>
    );
  }

  if (!isSignedIn) {
    return <Navigate to="/" replace />;
  }

  return <Layout>{children}</Layout>;
}

export default ProtectedRoute;
