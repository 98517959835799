// import backgroundImage /from "../assets/bg.jpg";
import Sidebar from "./sidebar";
import MobileSidebar from "./mobileSidebar";

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <div>
      <Sidebar />
      <MobileSidebar />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
}

export default Layout;
