import SignInOAuthButtons from "../components/auth/signin";
import BgImage from "../assets/bg.jpg";

function LandingPage() {
  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <SignInOAuthButtons />
    </div>
  );
}

export default LandingPage;
