import { useUser } from "@clerk/clerk-react";
import { Link, useLocation } from "react-router-dom";

import { RiHomeLine, RiSettingsLine, RiBillLine } from "@remixicon/react";

function Sidebar() {
  const { user, isLoaded } = useUser();
  const location = useLocation();
  const firstName = user?.firstName;
  const picture = user?.imageUrl;
  const email = user?.primaryEmailAddress?.emailAddress;

  const menuItems = [
    { name: "Home", icon: RiHomeLine, path: "/" },
    { name: "Plans & Billing", icon: RiBillLine, path: "/plans" },
    { name: "Settings", icon: RiSettingsLine, path: "/settings" },
  ];

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
        <div className="flex h-16 shrink-0 items-center">
          <h1 className="text-brand font-bold text-2xl">Braid Teams</h1>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {menuItems.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.path}
                      className={`group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ${
                        location.pathname === item.path
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                      }`}
                    >
                      <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            {user && isLoaded ? (
              <li className="-mx-6 mt-auto">
                <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src={picture}
                    alt={firstName + " avatar"}
                  />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">
                    {firstName}{" "}
                    <span className="text-xs font-light">{email}</span>
                  </span>
                </div>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
