import { useQuery } from "@tanstack/react-query";
import { useUser } from "@clerk/clerk-react";
import { useApi } from "./useApi";

interface UserData {
  id: string;
  name: string;
}

export function useUserData() {
  const { user } = useUser();
  const api = useApi();

  const fetchUserData = async (): Promise<UserData> => {
    const { data } = await api.get(`/v2/users/me`);
    return {
      id: data.uid,
      name: data.profile.first_name,
    };
  };

  return useQuery({
    queryKey: ["userData", user?.id],
    queryFn: () => fetchUserData(),
    enabled: !!user,
  });
}
